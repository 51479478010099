/*body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
*/
section.layout {
  min-height: 100vh;
}

header.ant-layout-header {
  width: 100%;
  padding: 0;
}

.nav-header {
  display: flex;
  max-width: 1200px;
  margin: auto;
  padding-left: 0;
}

.ant-layout-sider {
  position: relative;
}

.ant-layout-sider .ant-layout-sider-trigger {
  position: absolute;
}
