@import '~antd/dist/antd.css';
/*.App {
  padding: 20px;
  padding-top: 60px;
  max-width: 400px;
  margin: auto;
}*/

.logo {
  width: 120px;
  height: 31px;
  background: rgba(0, 0, 0, 0.05);
  margin: 16px 28px 16px 28px;
  float: left;
}

.ant-form-item {
  margin-bottom: 12px;
}

.login-page {
  height: 100vh;
}

.login-form {
  padding-top: 25vh;
  width: 300px;
  margin: auto;
}

.login-form-forgot {
  float: right;
}

.login-form-button {
  width: 100%;
}
